/**
 * @copyright 2020 @ DigiNet
 * @author ANHHUY
 * @create 09/18/2020
 * @Example 
 */

// import Api from "../../../services/api";

export const types = {
    W25F2015_LOAD_INFO_EMPLOYMENT: "W25F2015_LOAD_INFO_EMPLOYMENT",
    W25F2015_LOAD_INFO_EMPLOYMENT_SUCCESS: "W25F2015_LOAD_INFO_EMPLOYMENT_SUCCESS",
    W25F2015_GET_INFO_CANDIDATE: "W25F2015_GET_INFO_CANDIDATE",
    W25F2015_LOAD_STAGE: "W25F2015_LOAD_STAGE",
    W25F2015_GET_LIST_CANDIDATE: "W25F2015_GET_LIST_CANDIDATE",
    W25F2015_UPDATE_STATE: "W25F2015_UPDATE_STATE",
    W25F2015_GET_CACHE: "W25F2015_GET_CACHE",
    W25F2015_GET_LINK: "W25F2015_GET_LINK",
    W25F2015_GET_COUNT: "W25F2015_GET_COUNT",
    W25F2015_CHOOSE_CANDIDATE: "W25F2015_CHOOSE_CANDIDATE",
    W25F2015_GET_CBO_MAIL_TEMPLATE: "W25F2015_GET_CBO_MAIL_TEMPLATE",
    W25F2015_LOAD_MAIL_DATA: "W25F2015_LOAD_MAIL_DATA",
    W25F2015_SEND_MAIL: "W25F2015_SEND_MAIL",
    W25F2017_DELETE_CONFIRM: "W25F2017_DELETE_CONFIRM",
    W25F2017_DELETE_CANDIDATE: "W25F2017_DELETE_CANDIDATE",
    W25F2015_MOVE_STAGE: "W25F2015_MOVE_STAGE",
};

// get load form
export function getLoadInfoEmployment(params, cb) {
    return {
        type: types.W25F2015_LOAD_INFO_EMPLOYMENT,
        params,
        cb
    }
}

// LOAD STAGE
export function loadStage(params, cb) {
    return {
        type: types.W25F2015_LOAD_STAGE,
        params,
        cb
    }
}

// GET LIST CANDIDATE
export function getListCandidate(params, cb) {
    return {
        type: types.W25F2015_GET_LIST_CANDIDATE,
        params,
        cb
    }
}
export function updateStage(params, cb) {
    return {
        type: types.W25F2015_UPDATE_STATE,
        params,
        cb
    }
}

// export function getCache(params, cb) {
//     return {
//         type: types.W25F2015_GET_CACHE,
//         params,
//         cb
//     }
// }
// get INFO_CANDIDATE
// export function getInfoCandidate(params, cb) {
//     return {
//         type: types.W25F2015_GET_INFO_CANDIDATE,
//         params,
//         cb
//     }
// }
export function getLink(params, cb) {
    return {
        type: types.W25F2015_GET_LINK,
        params,
        cb
    }
}

export function getCount(params, cb) {
    return {
        type: types.W25F2015_GET_COUNT,
        params,
        cb
    }
}

export function deleteConfirm(params, cb) {
    return {
        type: types.W25F2017_DELETE_CONFIRM,
        params,
        cb
    }
}
export function deleteCandidate(params, cb) {
    return {
        type: types.W25F2017_DELETE_CANDIDATE,
        params,
        cb
    }
}
export function chooseCandidate(params, cb) {
    return {
        type: types.W25F2015_CHOOSE_CANDIDATE,
        params,
        cb
    }
}

export function getCboMailTemplate(params, cb) {
    return {
        type: types.W25F2015_GET_CBO_MAIL_TEMPLATE,
        params,
        cb
    }
}

export function loadMailData(params, cb) {
    return {
        type: types.W25F2015_LOAD_MAIL_DATA,
        params,
        cb
    }
}

export function sendMail(params, cb) {
    return {
        type: types.W25F2015_SEND_MAIL,
        params,
        cb
    }
}

export function moveStage(params, cb) {
    return {
        type: types.W25F2015_MOVE_STAGE,
        params,
        cb
    }
}