/**
 * @Copyright 2021 @DigiNet
 * @Author NAMDANG
 * @Create 9/17/2021
 * @Example
 */

export const types = {
    W29F2080_GET_DATA_GRID: "W29F2080_GET_DATA_GRID",
    W29F2080_DELETE_ROW: "W29F2080_DELETE_ROW",
    W29F2080_GET_CBO_APP_STATUS: "W29F2080_GET_CBO_APP_STATUS",
    W29F2080_GET_CBO_SHIFTS: "W29F2080_GET_CBO_SHIFTS",
};

export function getDataGrid(params, cb) {
    return {
        type: types.W29F2080_GET_DATA_GRID,
        params,
        cb,
    };
}

export function deleteRow(params, cb) {
    return {
        type: types.W29F2080_DELETE_ROW,
        params,
        cb,
    };
}

export function getCboAppStatus(params, cb) {
    return {
        type: types.W29F2080_GET_CBO_APP_STATUS,
        params,
        cb,
    }
}

export function getCboShifts(cb) {
    return {
        type: types.W29F2080_GET_CBO_SHIFTS,
        cb,
    }
}

