/**
 * @Copyright 2021 @DigiNet
 * @Author NAMDANG
 * @Create 9/17/2021
 * @Example
 */

import { delay } from "redux-saga";
import {cancel, take, takeLatest} from "redux-saga/effects";
import Api from "../../../services/api";
import { types } from "./W29F2080_actions";

export default function W29F2080Sagas() {
    return [watchGetDataGrid(), watchDeleteRow(), watchGetCboAppStatus(), watchGetCboShifts()];
}

export function* getDataGrid(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w29f2080/load-form", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get data grid error");
    }
}

export function* watchGetDataGrid() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2080_GET_DATA_GRID, getDataGrid);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* deleteRow(data) {
    try {
        yield delay(300);
        const response = yield Api.delete("/w29f2080/delete", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("delete row error");
    }
}

export function* watchDeleteRow() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2080_DELETE_ROW, deleteRow);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}


export function* getCboAppStatus(data) {
    try {
        yield delay(300);
        const response = yield Api.put("/w29f2080/load-app-status", data.params);
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get cbo app status error");
    }
}

export function* watchGetCboAppStatus() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2080_GET_CBO_APP_STATUS, getCboAppStatus);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}

export function* getCboShifts(data) {
    try {
        yield delay(300);
        const response = yield Api.get("/w29f2030/load-shift");
        if (response && response.data) {
            data.cb && data.cb(null, response.data);
        } else {
            data.cb && data.cb(response, null);
        }
    } catch (e) {
        console.log("get cbo shift error");
    }
}

export function* watchGetCboShifts() {
    while (true) {
        const watcher = yield takeLatest(types.W29F2080_GET_CBO_SHIFTS, getCboShifts);
        yield take(["LOGOUT", "NETWORK"]);
        yield cancel(watcher);
    }
}
