/**
 * @copyright 2019 @ DigiNet
 * @author NAMDANG
 * @create 9/17/2021
 * @Example
 */

export const types = {
    W29F2081_GET_DATA_FORM: "W29F2081_GET_DATA_FORM",
    W29F2081_GET_SHIFT: "W29F2081_GET_SHIFT",
    W29F2081_SAVE: "W29F2081_SAVE",
};

export function getDataForm(params, cb) {
    return {
        type: types.W29F2081_GET_DATA_FORM,
        params,
        cb,
    };
}

export function getShift(params, cb) {
    return {
        type: types.W29F2081_GET_SHIFT,
        params,
        cb,
    }
}

export function save(params, cb) {
    return {
        type: types.W29F2081_SAVE,
        params,
        cb,
    };
}
